import React, { useEffect } from "react";
import styled from "styled-components";
import SEO from "../components/global/seo";
import { Link } from "gatsby";

// Hooks, Querys & State
import { useAppContext } from "../state";

// Components
import {
  StyledHeading,
  StandardContainer,
  GridContainer,
} from "../components/common";

const StyledContainer = styled(StandardContainer)`
  > article {
    padding-top: 10rem;
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    width: 100%;
    text-align: center;
  }
  h1 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 1.5;
    text-transform: uppercase;
  }
  a {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid #0d214a;
    border-radius: 0.5rem;
    text-transform: uppercase;
    display: block;
  }
  @media screen and (min-width: 768px) {
    > article {
      padding-top: 16rem;
      padding-bottom: 16rem;
    }
  }
  @media screen and (min-width: 1024px) {
    > article {
      padding-top: 20rem;
      padding-bottom: 20rem;
    }
    h1 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h2 {
      font-size: 4.5rem;
      line-height: 1;
    }
  }
`;

const NotFound = () => {
  const { setReverseHeader, setHiddenHeaderFooter } = useAppContext();

  useEffect(() => {
    setReverseHeader(true);
    setHiddenHeaderFooter(false);
  }, [setReverseHeader, setHiddenHeaderFooter]);

  return (
    <>
      <SEO
        title="404: Not Found"
        description="We can't seem to find the page you are looking for."
      />
      <StyledContainer>
        <article>
          <h1 className="text-sm lg:text-base font-bold">404</h1>
          <h2 className="text-3xl md:text-5xl lg:text-7xl font-bold uppercase">
            We can't seem to find the page you are looking for.
          </h2>
          <Link to="/">Go back to home</Link>
        </article>
      </StyledContainer>
    </>
  );
};

export default NotFound;
